import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Home from './components/Home';
import WebDesigning from './components/WebDesigning';
import Seo from './components/Seo';
import SocialMedia from './components/SocialMedia';
import DigitalMarketing from './components/DigitalMarketing';
import GraphicDesign from './components/GraphicDesign';
import ContentStrategy from './components/ContentStrategy';
import Requests from './components/Requests';
import NotFound from './components/NotFound';
import Signup from './components/Signup';
import Login from './components/Login';
import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/signup' element={<Signup />} />
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Home />} />
        <Route path='/web-designing' element={<WebDesigning />} />
        <Route path='/seo' element={<Seo />} />
        <Route path='/social-media-marketing' element={<SocialMedia />} />
        <Route path='/digital-marketing' element={<DigitalMarketing />} />
        <Route path='/graphic-design' element={<GraphicDesign />} />
        <Route path='/content-strategy' element={<ContentStrategy />} />
        <Route path='/requests' element={<Requests />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
