import './index.css'

const NotFound = () => {
    return(
        <div className='not-found-cont'>
            <img src='https://cdni.iconscout.com/illustration/premium/thumb/404-error-3702358-3119147.png' alt='not found'/>
        </div>
    )
}

export default NotFound